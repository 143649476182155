import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { GridThree } from 'components/GridThree';

export const ItsepticView = () => {
    const images = useStaticQuery(graphql`
        query ItsepticImages {
            upper: file(name: { eq: "itseptic-upper" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lower: file(name: { eq: "itseptic-lower" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            main: file(name: { eq: "itseptic-main" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.itseptic.name}>
            <GridThree {...images} projectName={PROJECTS.itseptic.name} />
        </ProjectMainLayout>
    );
};
